// @flow
import React from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import { Table, Td, Tr } from '../components/table';
import Link from '../components/link';
import route from '../routes';
import {
  allDefinitions,
  getTermCount,
  getDefinitionCount,
} from '../utils/glossary-helpers';
import { withFrontMatter } from '../components';
import type { FrontMatter } from '../utils/types';
import Select from 'react-select';
import { color_primary, color_light, color_text } from '../utils/styles';

//region Select Component

const selectOptions = allDefinitions.map(({ termId, name }) => ({
  label: name,
  value: termId,
}));

const selectTheme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: color_light,
    primary: color_primary,
  },
});

// $FlowFixMe styled-components-v4
const StyledSelect = styled(Select).attrs({
  placeholder: 'Search Glossary',
  options: selectOptions,
})`
  margin-bottom: 1.66667rem;
`;

//endregion Select Component

// $FlowFixMe styled-components-v4
const LinkedTerm = styled(Link).attrs({
  hideExternalLink: true,
})`
  color: unset;

  &:hover,
  &:active {
    color: unset;
    text-decoration: underline ${color_text} solid;
  }
`;

//region Term Table

const termTable = (
  <>
    {allDefinitions.map((term) => {
      return (
        <Tr key={term.termId} id={term.termId}>
          <Td>
            <LinkedTerm to={`#${term.termId}`}>{term.name}</LinkedTerm>
          </Td>
          <Td>{term.fullDefinition}</Td>
        </Tr>
      );
    })}
  </>
);

//endregion Term Table

class Glossary extends React.Component<{ frontMatter: FrontMatter }> {
  render() {
    const { frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter} hideHorizontalRules scrollUpButton>
        <StyledSelect onChange={this.onChange} theme={selectTheme} />
        <Table>
          <tbody>{termTable}</tbody>
        </Table>
        <p>
          {getTermCount()} terms and {getDefinitionCount()} definitions.
          <br />
          Feel free to <Link to={route.page_about}>message me</Link> if you have
          feedback.
        </p>
        <hr />
      </Layout>
    );
  }

  onChange = ({ value }: any, { action }) => {
    if (action === 'select-option') {
      window.location.hash = '#' + value;
    }
  };
}

export default withFrontMatter('glossary')(Glossary);
